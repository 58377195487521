import React from 'react';
import { Button } from 'antd';

import "./CartButton.less"

const styleOptions = {
  className: 'cart-button',
  icon: 'shopping-cart',
  block: true
}

const CartButton = (props) => {
  const addButton = <Button type="primary" {...styleOptions} onClick={props.onAdd}>Add to cart</Button>;
  const removeButton = <Button type="danger" {...styleOptions} onClick={props.onRemove}>Remove from cart</Button>;
  return ( 
    props.alreadyInCart ? removeButton : addButton
   );
}
 
export default CartButton;