import React from "react";
import { Link } from "react-router-dom";

import { Col, Card } from "antd";

import "./ProductCard.less";

const ProductCard = (props) => {
  const coverImage = (
    <div className="card-image">
      {props.product.images.length > 0 ? (
        <Link to={`/product/${props.product._id}`}>
          <img
            alt={props.product.name}
            srcSet={props.product.images[0].versions
              .map((version) => `${version.src} ${version.width}`)
              .join(", ")}
            sizes="(max-width: 991px) 100vw, (max-width: 1199px) 33vw, 25vw"
            src={props.product.images[0].src}
          />
        </Link>
      ) : null}
    </div>
  );

  return (
    <Col key={props.product._id} xs={24} lg={12} xl={8}>
      <Card
        className="product-card"
        style={{ marginTop: props.spacing }}
        bordered={false}
        hoverable={false}
        cover={coverImage}
      >
        <p>
          <Link to={`/product/${props.product._id}`}>{props.product.name}</Link>
        </p>
        <p>{props.product.brand ? props.product.brand.name : "n.A"}</p>
        <p>{props.product.size || "n.A"}</p>
      </Card>
    </Col>
  );
};

export default ProductCard;
