import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Row, Col, List, Button, message } from "antd";

import BottomBar from "./UI_Elements/BottomBar";
import CheckoutForm from "./Cart/CheckoutForm";

import { api } from "../helpers/apiHelper";

import "./Cart.less";

class Cart extends Component {
  state = {
    products: [],
  };

  componentDidMount = () => {
    if (this.props.rootInitialised && this.props.user.id) {
      this.getCart();
    }
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.rootInitialised === false &&
      this.props.rootInitialised === true &&
      this.props.user.id
    ) {
      this.getCart();
    }
  };

  getCart = () => {
    api
      .get("/cart?populate=true")
      .then((res) => {
        if (res.data.object) {
          this.setState({ products: res.data.object.products });
        } else {
          this.setState({ products: [] });
        }
      })
      .catch((err) => {
        message.error("There was an error fetching your data!");
        console.error(err);
      });
  };

  handleRemoveFromCart = (productId) => {
    api
      .delete(`/cart/${productId}`)
      .then((res) => {
        this.props.refreshCart(res.data.object);
        this.getCart();
      })
      .catch((err) => {
        message.error("There was an error fetching your data!");
        console.error(err);
      });
  };

  handleCheckout = (values) => {
    api
      .post("/cart/checkout", values)
      .then((res) => {
        message.success("Your order has been successfully submitted!");
        this.props.refreshCart(null);
        this.props.history.push("/");
      })
      .catch((err) => {
        message.error("There was an submitting your order!");
        console.error(err);
      });
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  renderItem = (item) => (
    <List.Item
      actions={[
        <Button
          size="small"
          icon="delete"
          type="danger"
          onClick={() => this.handleRemoveFromCart(item._id)}
          ghost
        />,
      ]}
    >
      <List.Item.Meta
        avatar={
          <img
            alt={item.name}
            srcSet={item.images[0].versions
              .map((version) => `${version.src} ${version.width}`)
              .join(", ")}
            sizes="(max-width: 991px) 10vw, (max-width: 1199px) 5vw, 3vw"
            src={item.images[0].src}
          />
        }
        title={<Link to={`/product/${item._id}`}>{item.name}</Link>}
        description={[
          item.category ? item.category.name : "n.A",
          item.size || "n.A",
          item.brand ? item.brand.name : "n.A",
        ].join(" | ")}
      />
    </List.Item>
  );

  render() {
    const sideStyle = {
      xxl: { span: 5, offset: 2 },
      xl: { span: 8, offset: 2 },
      lg: { span: 9, offset: 2 },
      sm: { span: 10, offset: 1 },
    };

    return (
      <Layout>
        <Row gutter={0}>
          <Col
            className="product-list"
            xxl={{ span: 7, offset: 5 }}
            xl={{ span: 10, offset: 2 }}
            lg={{ span: 11, offset: 1 }}
            sm={{ span: 13, offset: 0 }}
            xs={{ span: 24, offset: 0 }}
          >
            <List
              itemLayout="horizontal"
              dataSource={this.state.products}
              renderItem={this.renderItem}
            />
          </Col>
          <Col {...sideStyle} style={{ position: "sticky", top: 131 }}>
            <CheckoutForm onCheckout={this.handleCheckout} />
          </Col>
        </Row>
        {this.props.mobile && (
          <React.Fragment>
            <div className="spacer" />
            <BottomBar>
              <Button
                shape="circle"
                icon="left"
                size="large"
                onClick={this.handleBack}
              />
            </BottomBar>
          </React.Fragment>
        )}
      </Layout>
    );
  }
}

export default Cart;
