import React from "react";

import { Collapse, Icon, Col, Checkbox } from "antd";

const { Panel } = Collapse;

function CheckboxFilter(Component) {
  return class extends Component {
    renderCheckbox = item => (
      <Col key={item._id} span={22} offset={2}>
        <Checkbox
          checked={item.selected}
          onChange={e =>
            this.props.onFilterChange(
              this.props.panelKey,
              item._id,
              e.target.checked
            )
          }
          disabled={item.results === 0}
        >
          {item.name} {item.amount ? `(${item.amount})` : null}
        </Checkbox>
      </Col>
    );

    render() {
      return (
        <Component
          {...this.props}
          header={
            <span>
              <Icon type={this.props.icon} />
              &nbsp; {this.props.title}
            </span>
          }
        >
          {this.props.options
            ? this.props.options.map(item =>
                this.renderCheckbox(
                  item,
                  this.props.panelKey,
                  this.props.onFilterChange
                )
              )
            : null}
        </Component>
      );
    }
  };
}

export default CheckboxFilter(Panel);
