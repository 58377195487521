import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import { Form, Input, Select, Checkbox, Button, message } from "antd";

import { api } from "../helpers/apiHelper";

const { Option } = Select;

class Register extends Component {
  state = {
    registering: false,
    autoCompleteResult: [],
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const user = {
          email: values["email"],
          firstName: values["firstName"],
          lastName: values["lastName"],
          mobilePhone: `+${values["prefix"]}${values["phone"]}`,
          password: values["password"],
        };
        this.setState({ registering: true });
        api
          .post("/users/register", { user })
          .then((res) => {
            message.success(
              "Registration successful! please check your e-mail."
            );
            this.setState({ registering: false });
            this.props.history.push("/login");
          })
          .catch((err) => {
            const res = err.response;
            if (res && res.data.object.hasOwnProperty("fieldErrors")) {
              const errorMessages = Object.values(
                res.data.object.fieldErrors
              ).map((elem) =>
                elem.reduce(
                  (acc, problem) => acc.concat(problem.message, " "),
                  ""
                )
              );
              message.error(errorMessages.join(""));
            } else {
              message.error(
                "Oops, something went wrong. Please try again later or contact us."
              );
              console.error(err);
            }
            this.setState({ registering: false });
          });
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords do not match!");
    } else {
      callback();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    const prefixSelector = getFieldDecorator("prefix", {
      initialValue: "41",
    })(
      <Select style={{ width: 70 }}>
        <Option value="41">+41</Option>
        <Option value="44">+44</Option>
        <Option value="39">+39</Option>
      </Select>
    );

    if (this.props.user.id) {
      return <Redirect to="/" />;
    } else {
      return (
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Form.Item label="E-mail">
            {getFieldDecorator("email", {
              rules: [
                {
                  type: "email",
                  message: "Please, insert a valid e-mail!",
                },
                {
                  required: true,
                  message: "Please give us your e-mail!",
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Password" hasFeedback>
            {getFieldDecorator("password", {
              rules: [
                {
                  required: true,
                  message: "Please input your password!",
                },
                {
                  min: 6,
                  message: "Must be at least 6 characters!",
                },
                {
                  pattern: /(?=.*[a-z])/,
                  message: "Must contain at least a lower case letter!",
                },
                {
                  pattern: /(?=.*[A-Z])/,
                  message: "Must contain at least an upper case letter!",
                },
                {
                  pattern: /(?=.*[0-9])/,
                  message: "Must contain at least a digit!",
                },
              ],
            })(<Input.Password />)}
          </Form.Item>
          <Form.Item label="Confirm Password" hasFeedback>
            {getFieldDecorator("confirm", {
              rules: [
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                {
                  validator: this.compareToFirstPassword,
                },
              ],
            })(<Input.Password />)}
          </Form.Item>
          <Form.Item label="First name">
            {getFieldDecorator("firstName", {
              rules: [
                {
                  required: true,
                  message: "Please input your first name!",
                  whitespace: true,
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Last name">
            {getFieldDecorator("lastName", {
              rules: [
                {
                  required: true,
                  message: "Please input your last name!",
                  whitespace: true,
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Phone Number">
            {getFieldDecorator("phone", {
              rules: [
                { required: true, message: "Please input your phone number!" },
              ],
            })(
              <Input addonBefore={prefixSelector} style={{ width: "100%" }} />
            )}
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            {getFieldDecorator("agreement", {
              valuePropName: "checked",
              rules: [
                {
                  validator: (rule, value, callback) =>
                    value
                      ? callback()
                      : callback("Please accept our terms & conditions!"),
                },
              ],
            })(
              <Checkbox>
                I have read and accept the{" "}
                <a
                  href="https://www.rent-a-porter.ch/en/rental-policy"
                  targer="_blank"
                >
                  rental policy
                </a>
              </Checkbox>
            )}
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button
              type="primary"
              htmlType="submit"
              loading={this.state.registering}
            >
              Register
            </Button>
          </Form.Item>
        </Form>
      );
    }
  }
}

export default Form.create({ name: "register" })(Register);
