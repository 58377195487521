import React, { Component } from "react";
import moment from "moment";
import { Form, DatePicker, Select, Button, Descriptions } from "antd";

import "./OrderForm.less";

const { Option } = Select;

class OrderForm extends Component {
  state = {
    submitting: false,
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.order !== prevProps.order) this.initFields();
  };

  initFields = () => {
    const order = this.props.order;
    const fields = {};
    for (const field in this.props.form.getFieldsValue()) {
      if (Object.hasOwnProperty.call(order, field)) {
        fields[field] = field.endsWith("Date")
          ? moment(order[field])
          : order[field];
      }
    }
    this.props.form.setFieldsValue({ ...fields });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSave(values);
      }
    });
  };

  validDate = (rule, value, callback) => {
    const getFieldValue = this.props.form.getFieldValue;
    const pickupDate = getFieldValue("pickupDate");
    const returnDate = value;
    if (pickupDate < returnDate) {
      callback();
    } else {
      callback("Return date must be after pickup date!");
    }
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      wrapperCol: {
        lg: { span: 24 },
        xl: { span: 24 },
      },
    };
    const dateSettings = {
      showToday: false,
      format: "dd. DD.MM.YY",
      allowClear: false,
    };
    const { user, order } = this.props;
    return (
      <React.Fragment>
        <Descriptions title="Client" column={1} size="small" bordered>
          <Descriptions.Item label="Name">{`${user.firstName} ${user.lastName}`}</Descriptions.Item>
          <Descriptions.Item label="Phone">
            {user.mobilePhone}
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            <a
              href={`mailto:${user.email}?subject=Rent-%C3%A0-Porter%20Order%20%23${order.number}`}
            >
              {user.email}
            </a>
          </Descriptions.Item>
        </Descriptions>
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          className="checkout-form"
        >
          <Form.Item>
            <Form.Item className="double">
              {getFieldDecorator("pickupDate", {
                rules: [{ required: true }],
              })(
                <DatePicker
                  {...dateSettings}
                  placeholder="Pickup date"
                  disabledDate={disabledDate()}
                />
              )}
            </Form.Item>
            <span className="form-separator">-</span>
            <Form.Item className="double">
              {getFieldDecorator("returnDate", {
                rules: [{ required: true, validator: this.validDate }],
              })(
                <DatePicker
                  {...dateSettings}
                  placeholder="Return date"
                  disabledDate={disabledDate(getFieldValue("pickupDate"))}
                />
              )}
            </Form.Item>
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("reason", {
              rules: [{ required: true }],
            })(
              <Select placeholder="Reason for the rental...">
                <Option value="professional">for a professional shoot</Option>
                <Option value="personal">for private use</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item>
            <Button type="default" onClick={this.props.onReset} block>
              Reset
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Save
            </Button>
          </Form.Item>
        </Form>
      </React.Fragment>
    );
  }
}

export default Form.create({ name: "order-form" })(OrderForm);

function disabledDate(startDate = moment()) {
  return function (current) {
    // Can not select days before today and today
    return !(current.isoWeekday() < 6) || current <= startDate.endOf("day");
  };
}
