import React, { Component } from "react";

import { Collapse, Button } from "antd";

import "./Filters.less";

import SelectFilter from "./Filters/SelectFilter";
import CheckboxFilter from "./Filters/CheckboxFilter";

class Filters extends Component {
  render() {
    return (
      <div className="product-filter">
        <SelectFilter
          options={this.props.genders}
          onFilterChange={this.props.onFilterChange}
          filterName="genders"
          icon="man"
          placeholder="Select gender..."
        />
        <SelectFilter
          options={this.props.types}
          onFilterChange={this.props.onFilterChange}
          filterName="types"
          icon="bars"
          placeholder="Select type..."
        />
        <Button onClick={this.props.onReset} type="primary">
          Reset
        </Button>
        <Collapse
          expandIconPosition="right"
          bordered={false}
          defaultActiveKey={getActiveKeys(this.props)}
        >
          <CheckboxFilter
            key="categories"
            options={this.props.categories}
            onFilterChange={this.props.onFilterChange}
            icon="appstore"
            title="Categories"
          />
          <CheckboxFilter
            key="colors"
            options={this.props.colors}
            onFilterChange={this.props.onFilterChange}
            icon="bg-colors"
            title="Colors"
          />
          <CheckboxFilter
            key="sizeGroups"
            options={this.props.sizeGroups}
            onFilterChange={this.props.onFilterChange}
            icon="sliders"
            title="Sizes"
          />
        </Collapse>
      </div>
    );
  }
}

export default Filters;

function getActiveKeys(props) {
  return Object.keys(props).filter(
    (propName) =>
      props[propName] instanceof Array &&
      props[propName].some((option) => option.selected)
  );
}
