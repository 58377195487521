import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Layout, message } from "antd";

import {
  api,
  authApi,
  sessionExists,
  refreshToken,
  logout,
} from "./helpers/apiHelper";

import Head from "./components/Head";
import Foot from "./components/Foot";
import Gallery from "./components/Gallery";
import Product from "./components/Product";
import Cart from "./components/Cart";
import Login from "./components/Login";
import Logout from "./components/Logout";
import Register from "./components/Register";
import Order from "./components/Order";

import "./App.less";

const { Content } = Layout;

const mobileBreakpoint = 576;
const refreshInterval = 4 * 60 * 1000;

class App extends Component {
  constructor(props) {
    super(props);
    if (sessionExists()) {
      this.initialAuth = refreshToken();
    } else {
      this.initialAuth = Promise.resolve(false);
    }

    this.state = {
      mobile: window.innerWidth < mobileBreakpoint,
      user: {
        id: null,
      },
      cart: null,
      rootInitialised: false,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.initialAuth
      .then((isAuthenticated) => {
        if (isAuthenticated) {
          const getUser = this.getUser();
          const getCart = this.getCart();
          this.refreshInterval = setInterval(refreshToken, refreshInterval);
          return Promise.all([getUser, getCart]);
        } else {
          return Promise.resolve();
        }
      })
      .then(() => {
        this.setState({ rootInitialised: true });
      })
      .catch((err) => {
        console.error(err);
        logout();
      });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    clearInterval(this.refreshInterval);
  }

  handleResize = () => {
    this.setState({ mobile: window.innerWidth < mobileBreakpoint });
  };

  getUser = () => {
    return authApi
      .get("/user")
      .then((res) => {
        this.setState({ user: res.data.user });
      })
      .catch((err) => {
        message.error("There was an error fetching your data!");
        console.error(err);
      });
  };

  getCart = () => {
    return api
      .get("/cart")
      .then((res) => {
        this.setState({ cart: res.data.object });
      })
      .catch((err) => {
        message.error("There was an error fetching your data!");
        console.error(err);
      });
  };

  setCart = (cart) => {
    this.setState({ cart });
  };

  render() {
    const padding = this.state.mobile ? "30px" : "50px";
    const styler = {
      style: {
        paddingLeft: padding,
        paddingRight: padding,
      },
    };
    return (
      <div className="wrapper">
        <Layout>
          <Router>
            <Head {...styler} {...this.state} />
            <Content {...styler} className="content-spacing">
              <Route
                path="/"
                exact
                render={(props) => <Gallery {...props} {...this.state} />}
              />
              <Route
                path="/product/:id"
                exact
                render={(props) => (
                  <Product
                    {...props}
                    {...this.state}
                    refreshCart={this.setCart}
                  />
                )}
              />
              <Route
                path="/cart"
                exact
                render={(props) => (
                  <Cart {...props} {...this.state} refreshCart={this.setCart} />
                )}
              />
              <Route
                path="/login"
                render={(props) => <Login {...props} {...this.state} />}
              />
              <Route
                path="/logout"
                render={(props) => <Logout {...props} {...this.state} />}
              />
              <Route
                path="/register"
                render={(props) => <Register {...props} {...this.state} />}
              />
              <Route
                path="/order/:id"
                render={(props) => <Order {...props} {...this.state} />}
              />
            </Content>
            {!this.state.mobile && <Foot />}
          </Router>
        </Layout>
      </div>
    );
  }
}

export default App;
