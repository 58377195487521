import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Row, Col, List, Button, message, Typography } from "antd";

import BottomBar from "./UI_Elements/BottomBar";
import OrderForm from "./Order/OrderForm";

import { api } from "../helpers/apiHelper";

import "./Order.less";

const { Title } = Typography;

class Order extends Component {
  state = {
    order: {},
    products: [],
    categories: [],
    brands: [],
    user: {},
  };

  initialState = {};

  componentDidMount = () => {
    if (this.props.rootInitialised && this.props.user.id) {
      this.getOrder(this.props.match.params.id);
    }
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.rootInitialised === false &&
      this.props.rootInitialised === true &&
      this.props.user.id
    ) {
      this.getOrder(this.props.match.params.id);
    }
  };

  getOrder = (orderId) => {
    let order = {};
    let products = [];
    let userReq;
    api
      .get(`/orders/${orderId}`)
      .then((res) => {
        if (res.data.object) {
          order = res.data.object;
          userReq = api.get(`/users/${order.user}`);
          return api.get(`/products?_id=${order.products.join(",")}`);
        }
      })
      .then((res) => {
        if (res.data.object) {
          products = res.data.object;
        }
        const categories = Array.from(
          new Set(products.map((e) => e.category).filter((e) => e))
        );
        const brands = Array.from(
          new Set(products.map((e) => e.brand).filter((e) => e))
        );
        return Promise.all([
          categories.length
            ? api.get(`/categories?_id=${categories.join(",")}`)
            : Promise.resolve({ data: {} }),
          brands.length
            ? api.get(`/brands?_id=${brands.join(",")}`)
            : Promise.resolve({ data: {} }),
          userReq,
        ]);
      })
      .then((res) => {
        const state = {
          order,
          products,
          categories: res[0].data.object || [],
          brands: res[1].data.object || [],
          user: res[2].data.object,
        };
        this.setState(state);
        this.initialState = JSON.stringify(state);
      })
      .catch((err) => {
        message.error("There was an error fetching your data!");
        console.error(err);
      });
  };

  handleRemoveFromOrder = (productId) => {
    const i = this.state.products.findIndex((e) => e._id === productId);
    const order = this.state.order;
    const products = this.state.products;
    products.splice(i, 1);
    order.products = products.map((e) => e._id);
    this.setState({ products, order });
  };

  handleSave = (values) => {
    const order = this.state.order;
    api
      .put(`/orders/${this.props.match.params.id}`, { ...order, ...values })
      .then((res) => {
        message.success("The order has been successfully updated!");
        this.getOrder(this.props.match.params.id);
      })
      .catch((err) => {
        message.error("There was an updating the order!");
        console.error(err);
      });
  };

  handleCancel = () => {
    this.setState(JSON.parse(this.initialState));
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  renderItem = (item) => {
    const brand = this.state.brands.find((e) => item.brand === e._id);
    const category = this.state.categories.find((e) => item.category === e._id);
    return (
      <List.Item
        actions={[
          <Button
            size="small"
            icon="delete"
            type="danger"
            onClick={() => this.handleRemoveFromOrder(item._id)}
            ghost
          />,
        ]}
      >
        <List.Item.Meta
          avatar={
            <img
              alt={item.name}
              srcSet={item.images[0].versions
                .map((version) => `${version.src} ${version.width}`)
                .join(", ")}
              sizes="(max-width: 991px) 10vw, (max-width: 1199px) 5vw, 3vw"
              src={item.images[0].src}
            />
          }
          title={<Link to={`/product/${item._id}`}>{item.name}</Link>}
          description={[
            brand ? brand.name : "n.A",
            item.size || "n.A",
            category ? category.name : "n.A",
          ].join(" | ")}
        />
      </List.Item>
    );
  };

  render() {
    const sideStyle = {
      xxl: { span: 5, offset: 2 },
      xl: { span: 8, offset: 2 },
      lg: { span: 9, offset: 2 },
      sm: { span: 10, offset: 1 },
    };

    return (
      <Layout>
        <Row gutter={0}>
          <Col
            xxl={{ span: 7, offset: 5 }}
            xl={{ span: 10, offset: 2 }}
            lg={{ span: 11, offset: 1 }}
            sm={{ span: 13, offset: 0 }}
            xs={{ span: 24, offset: 0 }}
          >
            <Title level={2}>Order #{this.state.order.number}</Title>
            <List
              itemLayout="horizontal"
              dataSource={this.state.products}
              renderItem={this.renderItem}
            />
          </Col>
          <Col {...sideStyle} style={{ position: "sticky", top: 131 }}>
            <OrderForm
              onSave={this.handleSave}
              onReset={this.handleCancel}
              order={this.state.order}
              user={this.state.user}
            />
          </Col>
        </Row>
        {this.props.mobile && (
          <BottomBar>
            <Button
              shape="circle"
              icon="left"
              size="large"
              onClick={this.handleBack}
            />
          </BottomBar>
        )}
      </Layout>
    );
  }
}

export default Order;
