import React, { Component } from "react";

import { Layout } from "antd";

const { Footer } = Layout;

class Foot extends Component {
  state = {};
  render() {
    return (
      <Footer style={{ textAlign: "center" }}>
        Rent-à-Porter.ch ©{new Date().getFullYear()} – Powered by EMBRIO.tech
      </Footer>
    );
  }
}

export default Foot;
