import React from "react";

import { Descriptions } from "antd";

import "./ProductDescription.less";

const Item = Descriptions;

const ProductDescription = (props) => {
  return (
    <Descriptions
      className="product-card"
      layout="horizontal"
      colon={false}
      column={1}
    >
      <Item label="Product#">{pad(props.product.number, 4)}</Item>
      <Item label="Name">{props.product.name}</Item>
      <Item label="Brand">
        {props.product.brand ? props.product.brand.name : "n.A"}
      </Item>
      <Item label="Size">{props.product.size}</Item>
      {props.product.details && (
        <Item label="Details">{props.product.details}</Item>
      )}
    </Descriptions>
  );
};

export default ProductDescription;

function pad(n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
