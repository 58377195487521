import React, { Component } from "react";

import DesktopMenu from "./Head/Desktop";
import MobileMenu from "./Head/Mobile";

class Head extends Component {
  render() {
    if (this.props.mobile) {
      return <MobileMenu {...this.props} />;
    } else {
      return <DesktopMenu {...this.props} />;
    }
  }
}

export default Head;
