import Axios from "axios";
import { config } from "../config";

function apiManager() {
  let token = null;

  const authApi = Axios.create({
    baseURL: config.authBaseUrl + "/api",
    withCredentials: true,
  });

  const api = Axios.create({
    baseURL: config.apiBaseUrl,
  });

  return {
    api,
    authApi,
    login,
    logout,
    validateToken,
    sessionExists,
    refreshToken,
  };

  function refreshToken() {
    return new Promise((resolve, reject) => {
      authApi
        .post(config.refreshEndpoint, null)
        .then((res) => {
          token = res.data.token;
          api.defaults.headers["authorization"] = formatAuthHeader();
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  function validateToken() {
    authApi.get(config.validateEndpoint).then((res) => {
      //TODO: Missing validation
    });
  }

  function logout() {
    const logoutCall = authApi.post(config.logoutEndpoint, null);
    logoutCall.then((res) => {
      token = null;
      localStorage.removeItem("userId");
      api.defaults.headers["authorization"] = formatAuthHeader();
    });
    return logoutCall;
  }

  function login(loginId, password) {
    const data = { loginId, password, applicationId: config.applicationId };
    const loginCall = authApi.post(config.loginEndpoint, data).then((res) => {
      token = res.data.token;
      localStorage.setItem("userId", res.data.user.id);
      api.defaults.headers["authorization"] = formatAuthHeader();
    });
    return loginCall;
  }

  function sessionExists() {
    return localStorage.getItem("userId") !== null;
  }

  function formatAuthHeader() {
    return token ? "Bearer " + token : undefined;
  }
}

export const { api, authApi, login, logout, sessionExists, refreshToken } =
  apiManager();
