import React from "react";

import { Button } from "antd";
import ReactImageMagnify from "react-image-magnify";

import "./ProductImage.less";

const ProductImage = props => {
  return (
    <div className="product-image">
      {!props.mobile && (
        <Button
          className="corner-btn"
          shape="circle"
          icon="left"
          size="large"
          onClick={props.onBack}
        />
      )}
      {props.product.images.length > 0 ? (
        <ReactImageMagnify
          smallImage={{
            alt: props.product.name,
            isFluidWidth: true,
            srcSet: props.product.images[0].versions
              .map(version => `${version.src} ${version.width}`)
              .join(", "),
            src: props.product.images[0].src
          }}
          largeImage={{
            src: props.product.images[0].src,
            width: 1250,
            height: 1875
          }}
          enlargedImagePosition="over"
          isHintEnabled={true}
        />
      ) : null}
    </div>
  );
};

export default ProductImage;
