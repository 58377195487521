import React from "react";
import { Layout } from "antd";

import "./BottomBar.less";

const BottomBar = props => {
  return (
    <Layout
      className="bottom-bar"
      style={{
        ...props.style
      }}
    >
      {props.children}
    </Layout>
  );
};

export default BottomBar;
