import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";

import { Form, Input, Icon, Button, message } from "antd";

import "../helpers/apiHelper";
import { config } from "../config";

import "./Login.less";
import { login } from "../helpers/apiHelper";

class Login extends Component {
  state = {};

  handleLogin = (loginId, password) => {
    return login(loginId, password)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        if (
          err.response &&
          404 <= err.response.status &&
          err.response.status < 500
        ) {
          message.error("The user was not found or the password is incorrect.");
        } else {
          message.error("Ooops, something went wrong");
          console.error(err);
        }
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.handleLogin(values.loginId, values.password);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    if (this.props.user.id) {
      return <Redirect to="/" />;
    } else {
      return (
        <Form onSubmit={this.handleSubmit} className="login-form">
          <Form.Item>
            {getFieldDecorator("loginId", {
              rules: [
                {
                  type: "email",
                  required: true,
                  message: "Please input your email!",
                },
              ],
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Email"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("password", {
              rules: [
                { required: true, message: "Please input your Password!" },
              ],
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="password"
                placeholder="Password"
              />
            )}
          </Form.Item>
          <Form.Item>
            <a href={`${config.authBaseUrl}/password/forgot`}>
              Forgot password
            </a>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Log in
            </Button>
            Or <Link to="/register">register now!</Link>
          </Form.Item>
        </Form>
      );
    }
  }
}

export default Form.create({ name: "normal_login" })(Login);
