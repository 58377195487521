import React from "react";

import { Select, Icon } from "antd";

const { Option } = Select;

const SelectFilter = (props) => {
  const selected = props.options.find((item) => item.selected);
  return (
    <Select
      placeholder={
        <span>
          <Icon type={props.icon} /> {props.placeholder}
        </span>
      }
      value={selected ? selected._id : undefined}
      onChange={(value) =>
        props.onFilterChange(props.filterName, value, null, true)
      }
      allowClear
    >
      {props.options.map((item) => (
        <Option key={item._id} value={item._id}>
          {item.name}
        </Option>
      ))}
    </Select>
  );
};

export default SelectFilter;
