import { logout } from "../helpers/apiHelper";

const Logout = ({ user, rootInitialised }) => {
  if (rootInitialised) {
    logout().then((res) => {
      window.location.assign("/");
    });
    return null;
  } else {
    return null;
  }
};

export default Logout;
