import React, { Component } from "react";
import moment from "moment";
import { Form, DatePicker, Select, Button } from "antd";

import "./CheckoutForm.less";

const { Option } = Select;

class CheckoutForm extends Component {
  state = {
    submitting: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onCheckout(values);
      }
    });
  };

  validDate = (rule, value, callback) => {
    const getFieldValue = this.props.form.getFieldValue;
    const pickupDate = getFieldValue("pickupDate");
    const returnDate = value;
    if (pickupDate < returnDate) {
      callback();
    } else {
      callback("Return date must be after pickup date!");
    }
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      wrapperCol: {
        lg: { span: 24 },
        xl: { span: 24 },
      },
    };
    const dateSettings = {
      showToday: false,
      format: "dd. DD.MM.YY",
      allowClear: false,
    };
    return (
      <Form
        {...formItemLayout}
        onSubmit={this.handleSubmit}
        className="checkout-form"
      >
        <Form.Item>
          <Form.Item className="double">
            {getFieldDecorator("pickupDate", {
              rules: [{ required: true }],
            })(
              <DatePicker
                {...dateSettings}
                placeholder="Pickup date"
                disabledDate={disabledDate()}
              />
            )}
          </Form.Item>
          <span className="form-separator">-</span>
          <Form.Item className="double">
            {getFieldDecorator("returnDate", {
              rules: [{ required: true, validator: this.validDate }],
            })(
              <DatePicker
                {...dateSettings}
                placeholder="Return date"
                disabledDate={disabledDate(getFieldValue("pickupDate"))}
              />
            )}
          </Form.Item>
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("reason", {
            rules: [{ required: true }],
          })(
            <Select placeholder="Reason for the rental...">
              <Option value="professional">for a professional shoot</Option>
              <Option value="personal">for private use</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Request a Quote
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: "checkout-form" })(CheckoutForm);

function disabledDate(startDate = moment()) {
  return function (current) {
    // Can not select days before today and today
    return !(current.isoWeekday() < 6) || current <= startDate.endOf("day");
  };
}
