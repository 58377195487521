import React from "react";
import Logo from "../../logo.svg";

import "./Mobile.less";

import { Layout } from "antd";

const { Header } = Layout;

const Mobile = (props) => {
  return (
    <React.Fragment>
      <Header className="custom-mobile-header" style={props.style}>
        <img src={Logo} alt="logo" />
      </Header>
      <div className="custom-mobile-spacer" />
    </React.Fragment>
  );
};

export default Mobile;
