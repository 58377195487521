import React, { Component } from "react";
import { Drawer, Row, Layout, Button, Badge, message } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";

import "./Gallery.less";

import { api } from "../helpers/apiHelper";

import BottomBar from "./UI_Elements/BottomBar";
import Filters from "./Gallery/Filters";
import ProductCard from "./Gallery/ProductCard";

const { Content, Sider } = Layout;

const picSpacing = 80;
const loadQty = 6;

const initialState = {
  products: [],
  genders: [],
  types: [],
  categories: [],
  colors: [],
  sizeGroups: [],
  visibleDrawer: false,
};

class Gallery extends Component {
  constructor(props) {
    super(props);

    const lastSearchState = JSON.parse(
      sessionStorage.getItem("lastSearchState")
    );

    if (lastSearchState !== null) {
      this.state = { ...lastSearchState };
    } else {
      this.state = { ...initialState };
    }
  }

  componentDidMount() {
    if (this.state.products.length === 0) {
      this.getGallery();
    }
  }

  resetGallery = () => {
    this.setState({ ...initialState }, this.getGallery);
  };

  getGallery = (start = 0, end = loadQty, increment = false) => {
    const filterParams = {
      genders: this.state.genders,
      types: this.state.types,
      categories: this.state.categories,
      colors: this.state.colors,
      sizeGroups: this.state.sizeGroups,
    };

    api
      .post(`/gallery?_start=${start}&_end=${end}`, filterParams)
      .then((res) => {
        const data = res.data.object;

        if (!increment) {
          this.setState({ ...data });
          sessionStorage.setItem(
            "lastSearchState",
            JSON.stringify({ ...data })
          );
        } else {
          const newProducts = this.state.products.concat(data.products);
          this.setState({ products: newProducts });
          sessionStorage.setItem(
            "lastSearchState",
            JSON.stringify({ ...data, products: newProducts })
          );
        }
      })
      .catch((err) => {
        message.error("There was an error fetching your data!");
        console.error(err);
      });
  };

  getUniqueEntries = (source) => {
    return Array.assign(new Set(source.map((item) => item.color)));
  };

  handleFilters = (filterName, id, value, unique = false) => {
    const filter = this.state[filterName];
    if (unique) {
      filter.forEach((item) => {
        const selected = item._id === id;
        item.selected = selected;
      });
    } else {
      filter.find((item) => item._id === id).selected = value;
    }
    this.setState({ [filterName]: filter }, this.getGallery);
    window.scrollTo(0, 0);
  };

  toggleDrawer = () => {
    this.setState({ visibleDrawer: !this.state.visibleDrawer });
  };

  renderCard = (product) => {
    return (
      <ProductCard key={product._id} product={product} spacing={picSpacing} />
    );
  };

  render() {
    const marginLeft = this.props.mobile ? 0 : 250;
    const padding = this.props.mobile ? 0 : `0 ${picSpacing}px`;
    const gutter = this.props.mobile ? 0 : picSpacing;
    const cart = this.props.cart;

    return (
      <Layout>
        {!this.props.mobile && (
          <Sider className="sider" width={250}>
            <Filters
              {...this.state}
              onFilterChange={this.handleFilters}
              onReset={this.resetGallery}
            />
          </Sider>
        )}
        <Content
          className="content"
          style={{
            marginLeft,
            padding,
          }}
        >
          <Row style={{ marginTop: -picSpacing }} gutter={gutter}>
            <InfiniteScroll
              dataLength={this.state.products.length}
              next={() =>
                this.getGallery(
                  this.state.products.length,
                  this.state.products.length + loadQty,
                  true
                )
              }
              hasMore={
                !(this.state.totalProducts === this.state.products.length)
              }
            >
              {this.state.products.map(this.renderCard)}
            </InfiniteScroll>
          </Row>
        </Content>
        {this.props.mobile && (
          <BottomBar>
            <Button
              shape="circle"
              icon="search"
              size="large"
              onClick={this.toggleDrawer}
            />
            {this.props.user.id && (
              <Badge count={cart ? cart.products.length : null}>
                <Button
                  shape="circle"
                  icon="shopping-cart"
                  size="large"
                  onClick={() => this.props.history.push("/cart")}
                />
              </Badge>
            )}
            {!this.props.user.id && (
              <Button
                shape="round"
                icon="login"
                size="large"
                onClick={() => this.props.history.push("/login")}
              >
                Login
              </Button>
            )}
            <Drawer
              placement="bottom"
              closable={true}
              onClose={this.toggleDrawer}
              visible={this.state.visibleDrawer}
              height="75vh"
            >
              <div className="mobile-filter">
                <Filters
                  className="mobile-filter"
                  {...this.state}
                  onFilterChange={this.handleFilters}
                  onReset={this.resetGallery}
                />
              </div>
            </Drawer>
          </BottomBar>
        )}
      </Layout>
    );
  }
}

export default Gallery;
