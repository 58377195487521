import React from "react";
import Logo from "../../logo.svg";

import "./Desktop.less";

import { Link } from "react-router-dom";
import { Menu, Layout, Icon, Badge } from "antd";

const { Header } = Layout;
const { SubMenu } = Menu;

const Desktop = (props) => {
  const cart = props.cart;
  const menuProps = {
    theme: "light",
    mode: "horizontal",
    selectable: false,
    className: "custom-desktop-menu",
  };

  const loggedOutMenuItem = (
    <Menu {...menuProps}>
      <Menu.Item key="user">
        <Link to="/login">
          Login <Icon type="user" />
        </Link>
      </Menu.Item>
    </Menu>
  );

  const loggedInMenuItem = (
    <Menu {...menuProps}>
      <SubMenu
        title={
          <span>
            Hi, {props.user.firstName} <Icon type="user" />
          </span>
        }
      >
        <Menu.Item key="logout">
          <Link to="/logout">Logout</Link>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="cart">
        <Link to="/cart">
          <Badge count={cart ? cart.products.length : null}>
            <Icon type="shopping-cart" />
          </Badge>
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <React.Fragment>
      <Header className="custom-desktop-header" style={props.style}>
        <Link to="/">
          <img src={Logo} alt="logo" className="logo" />
        </Link>
        {props.user.id ? loggedInMenuItem : loggedOutMenuItem}
      </Header>
      <div style={{ width: "100%", height: "107px" }} />
    </React.Fragment>
  );
};

export default Desktop;
