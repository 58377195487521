import React, { Component } from "react";
import { Layout, Row, Col, Button } from "antd";

import "./Product.less";

import { api } from "../helpers/apiHelper";
import BottomBar from "./UI_Elements/BottomBar";
import ProductImage from "./Product/ProductImage";
import ProductDescription from "./Product/ProductDescription";
import CartButton from "./Product/CartButton";

const desktopGutter = 50;

class Product extends Component {
  state = {
    product: {
      name: "",
      brand: { name: "" },
      images: [],
    },
  };
  componentDidMount() {
    this.getProduct(this.props.match.params.id);
  }

  getProduct = (id) => {
    api.get(`/gallery/${id}`).then((res) => {
      const product = res.data.object;
      this.setState({ product });
    });
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  handleAddToCart = () => {
    const productId = this.state.product._id;
    api.post(`/cart/${productId}`).then((res) => {
      this.props.refreshCart(res.data.object);
    });
  };

  handleRemoveFromCart = () => {
    const productId = this.state.product._id;
    api.delete(`/cart/${productId}`).then((res) => {
      this.props.refreshCart(res.data.object);
    });
  };

  render() {
    const gutter = this.props.mobile ? 0 : desktopGutter;

    return (
      <Layout>
        <Row gutter={gutter} type="flex" align="bottom">
          <Col
            xxl={{ span: 7, offset: 5 }}
            xl={{ span: 9, offset: 3 }}
            lg={{ span: 10, offset: 2 }}
            sm={{ span: 11, offset: 1 }}
          >
            <ProductImage
              product={this.state.product}
              mobile={this.props.mobile}
              onBack={this.handleBack}
            />
          </Col>
          <Col xxl={7} xl={9} lg={10} sm={11}>
            {this.props.user.id && (
              <CartButton
                alreadyInCart={
                  this.props.cart &&
                  this.props.cart.products.includes(this.state.product._id)
                }
                onAdd={this.handleAddToCart}
                onRemove={this.handleRemoveFromCart}
              />
            )}
            <ProductDescription product={this.state.product} />
          </Col>
        </Row>
        {this.props.mobile && (
          <BottomBar>
            <Button
              shape="circle"
              icon="left"
              size="large"
              onClick={this.handleBack}
            />
          </BottomBar>
        )}
      </Layout>
    );
  }
}

export default Product;
